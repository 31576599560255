// Broadcast Unpoly events "app:hidden" or "app:visible" when the browser tab becomes (in)active.
// See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

let hidden, visibilityChange

if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function documentHidden() {
  return document[hidden]
}

module.exports = {
  hidden: documentHidden,
  changeEvent: visibilityChange,
}
