export default class Timeout {
  constructor(callback, microseconds = 0) {
    this.callback = callback
    this.timerId = null
    this.startedAt = null
    this.remaining = microseconds
    this.resume()
  }

  pause() {
    this.clear()
    this.remaining -= new Date() - this.startedAt
  }

  resume() {
    this.clear()
    this.startedAt = new Date()
    this.timerId = window.setTimeout(this.callback, this.remaining)
  }

  clear() {
    window.clearTimeout(this.timerId)
  }
}
